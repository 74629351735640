<template>
    <section>
      <!-- <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha"> -->
      <!-- <folha>
        <br /><br /><br /><br />
        <div>
          <div class="header maiuscula" style="">
            <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
            <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
            <br />
          </div>
  
          <br />
          <br />
          <h3>{{ laudoSelecionado.laudoJson.titulo }}</h3>
          <br />
        </div>
  
        <div>
          <p></p>
          <p></p>
          <p></p>
          <p v-html="laudoSelecionado.laudoJson.personalizado"></p>
  
          <p></p>
          <p></p>
        </div>
        <br />
        <br />
        <footer>
          <span
            ><strong>Dra {{ medico.nome == 'Adriana Melo (curso)'? 'Adriana Melo': medico.nome }}</strong> </span
          ><br />
          <span
            ><strong>CRM/PB- {{ medico.crm }} </strong></span
          >
        </footer>
      </folha> -->
      <folha v-for="(exame, index) in folhasDoLaudo" :key="index">
        <br /><br /><br /><br />
        <div v-if="index + 1 == 1">
          <div class="header maiuscula" style="">
            <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
            <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          </div>
  
          <br />
          <br />
          <h3>INSERÇÃO DE DIU GUIADO POR TRANSVAGINAL</h3>
          <br />
        </div>
  
        <div style="max-width: 650px">
          <p></p>
          <p></p>
          <p></p>
          <p class="teste" v-html="exame"></p>
  
          <p></p>
          <p></p>
        </div>
        <br />
  
        <footer v-if="index + 1 == qtdFolhas">
          <span
            ><strong
              >Dra
              {{
                medico.nome == 'Adriana Melo (curso)'
                  ? 'Adriana Melo'
                  : medico.nome
              }}</strong
            > </span
          ><br />
          <span
            ><strong>CRM/PB- {{ medico.crm }} </strong></span
          >
        </footer>
      </folha>
    </section>
  </template>
  
  <script>
  import ImprecoesMixins from '@mixins/ImprecoesMixins';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import moment from 'moment';
  export default {
    mixins: [ImprecoesMixins],
    async mounted() {
      let folha = [];
  
      if (Array.isArray(this.laudoSelecionado.laudoJson)) {
        this.qtdFolhas = this.laudoSelecionado.laudoJson[0].folhas.length;
        this.laudoSelecionado.laudoJson.folhas =
          this.laudoSelecionado.laudoJson[0].folhas;
        this.titulo = this.laudoSelecionado.laudoJson[0].titulo;
      } else {
        this.qtdFolhas = this.laudoSelecionado.laudoJson.folhas.length;
        this.titulo = this.laudoSelecionado.laudoJson.titulo;
      }
  
      this.folhasDoLaudo = this.laudoSelecionado.laudoJson.folhas.map(element =>
        element.replaceAll('\n', '<br/>')
      );
  
      //this.laudoSelecionado.laudoJson.folhas = this.laudoSelecionado.laudoJson.folhas.replaceAll('\n','<br>')
  
      // while (todos.length > 0) {
      //   this.tamanhoElemento.push(todos.splice(0, 30));
      // }
    },
  
    data() {
      return {
        tamanhoElemento: [],
        folhasDoLaudo: [],
        qtdFolhas: 0,
        titulo: '',
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  $default-spacer: 0px;
  
  section {
    text-align: justify;
    margin-left: 10px;
    font-size: 11pt;
    font-family: 'Calibri';
  
    .header {
      display: flex;
      flex-direction: column;
    }
    // .teste {
  
    //   text-overflow: ellipsis;
    //   display: -webkit-box;
    //   line-height: 12px;     /* fallback */
    //   max-height: 32px;      /* fallback */
    //   -webkit-line-clamp: 13; /* number of lines to show */
    //   -webkit-box-orient: vertical
    // }
    .teste ::v-deep p {
      padding: 0;
      margin: 0;
      vertical-align: baseline;
      list-style: none;
      border: 0;
      display: block;
      line-height: 1.25;
    }
    .teste ::v-deep li {
      padding: 0;
      margin: 0;
      margin-left: 25px;
      vertical-align: baseline;
      line-height: 1.25;
    }
    .teste ::v-deep h3 {
      margin-left: 0px;
      line-height: 15px;
    }
    .teste ::v-deep h4 {
      padding: 0;
      margin: 0;
      margin-left: 0px;
      vertical-align: baseline;
      line-height: 15px;
      margin-left: 0px;
      margin-top: 1x;
    }
    .teste ::v-deep ul {
    }
  
    h3 {
      text-align: justify;
      margin-left: 0px;
      margin: $default-spacer * 0;
      padding: 0px;
      line-height: 15px;
    }
  
    div {
      margin-left: 0px;
    }
  
    h4 {
      word-wrap: break-word;
      text-align: justify;
      font-family: 'Calibri';
      margin-left: 0px;
      margin-top: 1x;
      padding: 2px;
    }
  
    p {
      word-wrap: break-word;
      text-align: justify;
      font-family: 'Calibri';
      margin-left: 0px;
      margin-top: 1x;
      padding: 2px;
      line-height: 5px;
    }
    br {
      display: block;
      margin-top: 0px;
      margin-left: 0px;
      line-height: 100%;
    }
    span {
      margin: $default-spacer * 0;
      margin-top: 15px;
      margin-left: 1px;
      line-height: 5px;
    }
    footer {
      bottom: 0;
      margin-left: 0px;
  
      width: 80%;
      height: 100px;
      text-align: left;
      line-height: 20px;
    }
  }
  </style>
  